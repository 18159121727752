@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/global" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables" as *;

.main-content {
  position: relative;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    animation: fadeIn;
    animation-duration: 5s;
    animation-iteration-count: 1;
    @include tablet {
      max-width: 666px;
    }
    @include desktop {
      max-width: 857px;
    }
  }

  &__large-logo-image {
    height: 250px;
    @include mobile {
      height: 320px;
    }
    @include tablet {
      height: 450px;
    }
  }

  &__paragraph {
    @include Body_1;
    color: $kelpsentinel-manatee;
    text-align: center;
    &:hover {
      color: $kelpsentinel-red;
      animation: pulse;
      animation-duration: 1s;
      animation-iteration-count: 1;
    }
  }

  &__buttons-container {
    margin-top: 16px;
    display: flex;
    gap: 16px;
  }

  &__button {
    cursor: pointer;
    @include Button_1;
    padding: 12px 20px;
    background-color: $kelpsentinel-red;
    border: 1px solid $kelpsentinel-red;
    border-radius: 6px;
    &:hover {
      animation: pulse;
      animation-duration: 1s;
      animation-iteration-count: 1;
    }
  }
}
