@use "./variables" as *;
@use "./mixins" as *;
@use "./colors" as *;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
  color: $kelpsentinel-black;
  outline: none;
}

/* WebKit browsers */
::-webkit-scrollbar {
  width: 2px; /* Adjust the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: $kelpsentinel-black;
  border-radius: 6px; /* Rounded corners */
}

::-webkit-scrollbar-thumb {
  background-color: $kelpsentinel-red-transparent;
  border-radius: 6px; /* Rounded corners */
}

.hidden-section {
  opacity: 0; /* Initially hide the section */
  transition: opacity 1s ease-in-out; /* Smooth transition for opacity */
}

.visible-section {
  opacity: 1; /* Show the section when it comes into view */
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
  width: 36px !important;
  min-width: 36px !important;
}

.mapboxgl-ctrl-geocoder {
  width: 33.3333% !important;
  font-size: 15px !important;
  line-height: 20px !important;
  max-width: 360px !important;
}
.mapboxgl-ctrl-geocoder .suggestions {
  font-size: 13px !important;
}

.mapboxgl-ctrl-geocoder--icon {
  top: 8px !important;
}

.mapboxgl-ctrl-geocoder--icon-close {
  width: 16px !important;
  height: 16px !important;
  margin-top: 3px !important;
  margin-right: 0 !important;
}

.mapboxgl-ctrl-geocoder--icon-geolocate {
  width: 18px !important;
  height: 18px !important;
  margin-top: 2px !important;
  margin-right: 0 !important;
}

.mapboxgl-ctrl-geocoder--icon-search {
  left: 7px !important;
  width: 20px !important;
  height: 20px !important;
}

.mapboxgl-ctrl-geocoder--input {
  height: 36px !important;
  padding: 6px 35px !important;
}

.mapboxgl-ctrl-geocoder--icon-loading {
  width: 26px !important;
  height: 26px !important;
  margin-top: -2px !important;
  margin-right: -5px !important;
}

.mapbox-gl-geocoder--error {
  color: #909090 !important;
  padding: 6px 12px !important;
  font-size: 16px !important;
  text-align: center !important;
}

.mapboxgl-ctrl-geocoder--powered-by {
  font-size: 11px !important;
}
