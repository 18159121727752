@use "../src/styles/partials/colors" as *;
@use "../src/styles/partials/global" as *;
@use "../src/styles/partials/mixins" as *;
@use "../src/styles/partials/variables" as *;

.App {
  flex-grow: 1;
  margin: 0;
  display: flex;
  flex-direction: column;
}
