@use "./variables" as *;
@use "./colors" as *;
@import url(//fonts.googleapis.com/css?family=Exo+2);
@import url(//fonts.googleapis.com/css?family=Inter);
@import url(//fonts.googleapis.com/css?family=Space+Mono);
@import url(//fonts.googleapis.com/css?family=Roboto);

// Breakpoint Mixins
@mixin mobile {
  @media screen and (min-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-breakpoint) {
    @content;
  }
}

// Type Kit Mixins

@mixin LogoTitle {
  font-family: "Roboto";
  color: $kelpsentinel-antiflash;
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
}

@mixin LogoSubTitle {
  font-family: "Roboto";
  color: $kelpsentinel-antiflash;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
}

@mixin H1 {
  font-family: "Exo 2";
  color: $kelpsentinel-antiflash;
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  @include tablet {
    font-size: 46px;
    line-height: 140%;
  }
  @include desktop {
    font-size: 58px;
    line-height: 120%;
  }
}

@mixin H2 {
  font-family: "Exo 2";
  color: $kelpsentinel-antiflash;
  font-weight: 600;
  font-size: 28px;
  line-height: 140%;
  @include tablet {
    font-size: 32px;
    line-height: 140%;
  }
  @include desktop {
    font-size: 48px;
    line-height: 120%;
  }
}

@mixin H3 {
  font-family: "Exo 2";
  color: $kelpsentinel-antiflash;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  @include tablet {
    font-size: 26px;
    line-height: 140%;
  }
  @include desktop {
    font-size: 42px;
    line-height: 120%;
  }
}

@mixin H4 {
  font-family: "Exo 2";
  color: $kelpsentinel-antiflash;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  @include tablet {
    font-size: 22px;
    line-height: 140%;
  }
  @include desktop {
    font-size: 36px;
    line-height: 120%;
  }
}

@mixin H5 {
  font-family: "Exo 2";
  color: $kelpsentinel-antiflash;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  @include tablet {
    font-size: 18px;
    line-height: 140%;
  }
  @include desktop {
    font-size: 30px;
    line-height: 120%;
  }
}

@mixin H6 {
  font-family: "Exo 2";
  color: $kelpsentinel-antiflash;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  @include desktop {
    font-size: 26px;
    line-height: 120%;
  }
}

@mixin LegendTitle {
  font-family: "Exo 2";
  color: $kelpsentinel-antiflash;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
}
@mixin LegendSubTitle {
  font-family: "Exo 2";
  color: $kelpsentinel-antiflash;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

@mixin LegendBody {
  font-family: "Inter";
  color: $kelpsentinel-antiflash;
  font-weight: 400;
  font-size: 12px;
  line-height: 200%;
}

@mixin Body_1 {
  font-family: "Inter";
  color: $kelpsentinel-antiflash;
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  @include tablet {
    font-size: 16px;
    line-height: 200%;
  }
  @include desktop {
    font-size: 20px;
    line-height: 156%;
  }
}

@mixin Body_2 {
  font-family: "Inter";
  color: $kelpsentinel-antiflash;
  font-weight: 400;
  font-size: 14px;
  line-height: 200%;
  @include tablet {
    font-size: 14px;
    line-height: 200%;
  }
  @include desktop {
    font-size: 18px;
    line-height: 156%;
  }
}

@mixin Body_3 {
  font-family: "Inter";
  color: $kelpsentinel-antiflash;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  @include tablet {
    font-size: 14px;
    line-height: 170%;
  }
  @include desktop {
    font-size: 16px;
    line-height: 156%;
  }
}

@mixin ProfileJobTitle {
  font-family: "Inter";
  color: $kelpsentinel-manatee;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  @include tablet {
    font-size: 14px;
    line-height: 200%;
  }
  @include desktop {
    font-size: 16px;
    line-height: 160%;
  }
}

@mixin ProfileSubTitle {
  font-family: "Inter";
  color: $kelpsentinel-antiflash;
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  @include tablet {
    font-size: 12px;
    line-height: 120%;
  }
  @include desktop {
    font-size: 14px;
    line-height: 120%;
  }
}

@mixin Title {
  font-family: "Inter";
  color: $kelpsentinel-antiflash;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  @include tablet {
    font-size: 16px;
    line-height: 160%;
  }
  @include desktop {
    font-size: 20px;
    line-height: 156%;
  }
}

@mixin Button_1 {
  font-family: "Inter";
  color: $kelpsentinel-antiflash;
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;
}

@mixin Button_2 {
  font-family: "Inter";
  color: $kelpsentinel-antiflash;
  font-weight: 500;
  font-size: 18px;
  line-height: 156%;
}

@mixin Footer {
  font-family: "Space Mono";
  color: $kelpsentinel-antiflash;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  @include tablet {
    font-size: 16px;
    line-height: 160%;
  }
  @include desktop {
    font-size: 20px;
    line-height: 156%;
  }
}
