@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/global" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables" as *;

#about:target {
  scroll-margin-top: 130px; /* Adjust this value to the height of your sticky header */
}

.about-content {
  margin-top: 88px;

  &__vertical-line {
    height: 100px;
  }
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include tablet {
      flex-direction: row;
      align-items: flex-end;
      gap: 254px;
    }
    @include desktop {
      gap: 551px;
    }
  }

  &__image-container {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 150px;
    left: 320px;
    @include desktop {
      top: 50px;
      left: 360px;
    }
  }

  &__image {
    rotate: -15deg;
    display: none;
    @include tablet {
      display: block;
      width: 230px;
    }
    @include desktop {
      width: 400px;
    }
  }

  &__intro {
    @include tablet {
      width: 321px;
    }
    @include tablet {
      width: 323.24px;
    }
  }

  &__explore-more {
    @include tablet {
      width: 321px;
    }
    @include desktop {
      width: 326px;
    }
  }

  &__sub-heading {
    @include Title;
    color: $kelpsentinel-manatee;
  }

  &__heading {
    margin-top: 16px;
    @include H2;
    @include desktop {
      @include H4;
    }
  }

  &__quote-box {
    margin-top: 16px;
    display: flex;
    gap: 24px;
  }

  &__paragraph {
    @include Body_1;
    color: $kelpsentinel-manatee;
    @include desktop {
      font-size: 16px;
      line-height: 32px;
    }
  }

  &__sentence {
    margin-top: 24px;
    @include Body_1;
    color: $kelpsentinel-manatee;
    @include desktop {
      font-size: 16px;
      line-height: 32px;
    }
  }

  &__button {
    cursor: pointer;
    @include Button_1;
    align-self: flex-start;
    margin-top: 24px;
    padding: 12px 20px;
    background-color: $kelpsentinel-red;
    border: 1px solid $kelpsentinel-red;
    border-radius: 6px;
    &:hover {
      animation: pulse;
      animation-duration: 1s;
      animation-iteration-count: 1;
    }
  }
}
