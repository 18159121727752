@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/global" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables" as *;

.list-content {
  margin-top: 88px;
  position: relative;

  &__image-container {
    display: flex;
    justify-content: center;
  }

  &__image {
    width: 265.42px;
    rotate: -15deg;
    @include tablet {
      width: 280px;
      position: absolute;
      top: 250px;
      left: 330px;
    }
    @include desktop {
      width: 400px;
      position: absolute;
      top: 90px;
      left: 400px;
    }
  }

  &__container {
    margin-top: 56px;
    display: grid;
    gap: 56px;
    @include tablet {
      grid-template-columns: 321px 321px;
      column-gap: 259px;
      row-gap: 144px;
    }
    @include desktop {
      grid-template-columns: 316px 316px;
      column-gap: 548px;
      row-gap: 160px;
    }
  }

  &__list-item {
    display: flex;
    gap: 20px;
  }

  &__item-bullet {
    height: 27px;
    width: 27px;
  }

  &__item-text {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__item-title {
    @include H3;
    @include desktop {
      @include H6;
    }
  }

  &__item-sentence {
    @include Body_1;
    color: $kelpsentinel-manatee;
    @include desktop {
      @include Body_3;
    }
  }
}
