@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/global" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables" as *;

.mapboxgl-ctrl-btn {
  cursor: pointer;
  border: none;
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 220px;
  right: 010px;
}

.mapboxgl-ctrl-icon {
  background-image: url("../../../assets/design-vectors/monochrome.svg"); /* Path to your custom icon */
}

.mobile-map-header {
  flex: 1;
  display: block;
  @include tablet {
    display: none;
  }
  background-color: $kelpsentinel-navy;
  border-bottom: 0.5px solid $kelpsentinel-white;
  height: 70px;
  z-index: 1000;
}

.map-container {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.info-box {
  position: absolute;
  bottom: 10px;
  left: 100px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  font-family: Arial, sans-serif;
  z-index: 100;
}

.legend {
  background: white;
  padding: 10px !important;
  @include tablet {
    padding: 20px !important;
  }
  font-family: Arial, sans-serif;
  position: absolute;
  bottom: 30px;
  right: 10px;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 1000;

  &__container {
    display: flex;
    flex-direction: column;
  }
  &__items {
    width: 100%;
    display: flex;
    flex-direction: column;
    &-row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
  }
}

.legend h4 {
  @include LegendTitle;
  color: $kelpsentinel-black;
}

.legend h5 {
  @include LegendSubTitle;
  color: $kelpsentinel-black;
  margin-bottom: 10px;
}

.legend div {
  @include LegendBody;
  color: black;
  display: flex;
  align-items: center;
}

.legend div span {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 8px;
}

.map-logo {
  background-color: $kelpsentinel-navy;
  border: 0.5px solid $kelpsentinel-white;
  border-radius: 6px;
  padding: 0px 10px;
  position: absolute;
  top: 10px;
  left: 20px;
  z-index: 1000;
  display: flex;
  gap: 5px;
  align-items: center;

  &__map-logo-text-title {
    @include LogoTitle;
  }

  &__map-logo-text-subtitle {
    @include LogoSubTitle;
  }

  &__map-logo-image {
    height: 55px;
    margin-bottom: 5px;
  }
}

.map-logo-header {
  margin-left: 10px;
  z-index: 1000;
  display: flex;
  gap: 5px;
  align-items: center;

  &__map-logo-text-title {
    @include LogoTitle;
  }

  &__map-logo-text-subtitle {
    @include LogoSubTitle;
  }

  &__map-logo-image {
    height: 55px;
    margin-bottom: 5px;
  }
}
