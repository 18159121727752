@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/global" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables" as *;

.image-section-one {
  margin-top: 88px;
  @include tablet {
    margin-top: 120px;
  }
  @include tablet {
    display: grid;
    grid-template-columns: auto 457px;
    column-gap: 80px;
  }

  @include desktop {
    display: grid;
    grid-template-columns: 478.57px 614.01px;
    column-gap: 107.43px;
    height: 638px;
  }

  &__vertical-line {
    height: 160px;
    @include mobile {
      height: 130px;
    }
    @include tablet {
      height: 100px;
    }
    @include desktop {
      height: 85px;
    }
  }

  &__image-block {
    position: relative;
    height: 450px;
    @include mobile {
      height: 550px;
    }
    @include desktop {
      height: 500px;
    }
  }

  &__image-one {
    position: absolute;
    top: 0;
    left: 0;
    width: 170px;
    height: 169.77px;
    @include mobile {
      height: 182.21px;
      width: 268.17px;
    }
    @include desktop {
      height: 233.34px;
      width: 343.58px;
    }
    border: 0.78px solid $kelpsentinel-red;
    border-radius: 10px;
    object-fit: cover;
  }

  &__image-two {
    position: absolute;
    top: 130.6px;
    left: 110.87px;
    width: 150px;
    height: 112.75px;
    @include mobile {
      left: 173.87px;
      height: 154.18px;
      width: 200.13px;
    }
    @include desktop {
      top: 167.73px;
      left: 222.34px;
      height: 198.22px;
      width: 256.23px;
    }
    border: 0.78px solid $kelpsentinel-red;
    border-radius: 10px;
    object-fit: cover;
  }

  &__image-three {
    position: absolute;
    top: 226.04px;
    left: 0;
    width: 150px;
    height: 100px;
    @include mobile {
      height: 168.19px;
      width: 168.11px;
    }
    @include desktop {
      top: 289.25px;
      height: 213.97px;
      width: 215.04px;
    }
    border: 0.78px solid $kelpsentinel-red;
    border-radius: 10px;
    object-fit: cover;
  }

  &__image-four {
    position: absolute;
    top: 310px;
    left: 130px;
    width: 150px;
    height: 114.18px;
    @include mobile {
      left: 134.34px;
      height: 200.23px;
      width: 200.13px;
    }
    @include desktop {
      top: 383.6px;
      left: 171.79px;
      height: 254.4px;
      width: 256.63px;
    }
    border: 0.78px solid $kelpsentinel-red;
    border-radius: 10px;
    object-fit: cover;
  }

  &__text-block {
    margin-top: 20px;
    @include tablet {
      margin-top: 56px;
    }
  }

  &__sub-heading {
    @include Title;
    color: $kelpsentinel-manatee;
  }

  &__heading {
    margin-top: 16px;
    @include H2;
    @include desktop {
      @include H3;
    }
  }

  &__quote-box {
    margin-top: 16px;
    display: flex;
    gap: 24px;
  }

  &__paragraph {
    @include Body_1;
    color: $kelpsentinel-manatee;
    @include desktop {
      @include Body_2;
    }
  }

  &__container {
    margin-top: 16px;
    margin-left: 16px;
    @include mobile {
      margin-left: 32px;
    }
    display: flex;
    flex-direction: column;
    gap: 19.39px;
  }

  &__list-item {
    display: flex;
    gap: 20px;
  }

  &__item-bullet {
    height: 26px;
    width: 26px;
  }

  &__item-text {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__item-title {
    @include H5;
    @include desktop {
      font-size: 22px;
      line-height: 120%;
    }
  }

  &__item-sentence {
    @include Body_1;
    color: $kelpsentinel-manatee;
  }

  &__explore-button {
    cursor: pointer;
    @include Button_1;
    align-self: flex-start;
    margin-top: 24px;
    padding: 12px 20px;
    background-color: $kelpsentinel-red;
    border: 1px solid $kelpsentinel-red;
    border-radius: 6px;
    &:hover {
      animation: pulse;
      animation-duration: 1s;
      animation-iteration-count: 1;
    }
  }
}
