@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/global" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables" as *;

@import "animate.css";

.header {
  background-color: transparent;
  margin-top: 20px;
  position: sticky;
  top: 0;
  &-has-scrolled {
    margin-top: 0px;
    background-color: $kelpsentinel-black;
    border-bottom: 2px solid $kelpsentinel-red;
  }
  padding: 0px 10px;
  @include mobile {
    padding: 0px 20px;
  }
  @include tablet {
    padding: 0px 64px;
  }
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;

  &__header-logo {
    visibility: hidden;
    &-has-scrolled {
      visibility: visible;
    }
    display: flex;
    gap: 5px;
    align-items: center;
  }

  &__header-logo-text-title {
    @include LogoTitle;
  }

  &__header-logo-text-subtitle {
    @include LogoSubTitle;
  }

  &__header-logo-image {
    height: 55px;
    margin-bottom: 5px;
  }

  &__header-menu {
    height: 20px;
    width: 24px;
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 54px;
  }

  &__link {
    @include H6;
    @include desktop {
      font-size: 16px;
      line-height: 28px;
    }
    &:hover {
      color: $kelpsentinel-red;
      animation: pulse;
      animation-duration: 1s;
      animation-iteration-count: 1;
    }
    &-button {
      cursor: pointer;
      color: $kelpsentinel-lightblack;
      background-color: $kelpsentinel-antiflash;
      padding: 2px 20px;
      border-radius: 8px;
      &:hover {
        background-color: $kelpsentinel-red;
        color: $kelpsentinel-antiflash;
        animation: pulse;
        animation-duration: 1s;
        animation-iteration-count: 1;
      }
    }
  }
}
