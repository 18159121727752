@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/global" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables" as *;

.footer {
  margin-top: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 41.85px;
  gap: 36px;

  &__top-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 56px;
    @include tablet {
      flex-direction: row;
      width: 896px;
      justify-content: space-between;
    }
  }

  &__bottom-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 56px;
    @include tablet {
      flex-direction: row;
      width: 896px;
      justify-content: space-between;
    }
  }

  &__footer-logo {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  &__footer-logo-text-title {
    @include LogoTitle;
  }

  &__footer-logo-text-subtitle {
    @include LogoSubTitle;
  }

  &__footer-logo-image {
    height: 55px;
    margin-bottom: 5px;
  }

  &__footer-cc-logo {
    height: 25px;
    @include tablet {
      height: 30px;
    }
    @include desktop {
      height: 35px;
    }
  }

  &__socials {
    display: flex;
    gap: 26px;
  }
  &__social-icons {
    height: 32px;
    width: 32px;
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 54px;
  }

  &__link {
    @include H6;
    @include desktop {
      font-size: 16px;
      line-height: 28px;
    }
    &-button {
      cursor: pointer;
      color: $kelpsentinel-lightblack;
      background-color: $kelpsentinel-antiflash;
      padding: 2px 20px;
      border-radius: 8px;

      &:hover {
        background-color: $kelpsentinel-red;
        color: $kelpsentinel-antiflash;
        animation: pulse;
        animation-duration: 1s;
        animation-iteration-count: 1;
      }
    }
  }

  &__legal {
    @include Footer;
  }
  &__divider-mobile {
    width: 220px;
    @include mobile {
      width: auto;
    }
    @include tablet {
      display: none;
    }
  }

  &__divider-tablet {
    display: none;
    @include tablet {
      display: block;
    }
  }
}
