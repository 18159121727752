@use "../../styles/partials/colors" as *;
@use "../../styles/partials/global" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.landing {
  position: relative;
  background-color: $kelpsentinel-lightblack;
  background-image: url("../../../src/assets/large-images/stars 1.png");
  background-size: 500px 309px;
  background-position: 25% 45%;
  @include tablet {
    background-size: 1000px 619px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content {
    width: 270px;
    @include mobile {
      width: 374px;
    }
    @include tablet {
      width: 896px;
    }
    @include desktop {
      width: 1200px;
    }
  }
}

.landing::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(black, $kelpsentinel-lightblack);
  z-index: -1; /* Ensure it is behind the content */
}
