@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/global" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables" as *;

#team:target {
  scroll-margin-top: 100px; /* Adjust this value to the height of your sticky header */
}

.our-team {
  margin-top: 88px;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include tablet {
      width: 666px;
    }
  }

  &__vertical-line {
    height: 510px;
    width: 4px;
    @include mobile {
      height: 350px;
    }
    @include tablet {
      height: 190px;
    }
    @include desktop {
      height: 170px;
    }
  }

  &__sub-heading {
    @include Title;
    color: $kelpsentinel-manatee;
  }

  &__heading {
    margin-top: 16px;
    @include H2;
    @include desktop {
      @include H3;
    }
  }

  &__quote-box {
    margin-top: 16px;
    display: flex;
    gap: 12px;
    @include tablet {
      gap: 30px;
    }
    @include tablet {
      width: 662px;
    }
  }

  &__paragraph {
    @include Body_1;
    color: $kelpsentinel-manatee;
    @include desktop {
      @include Body_3;
    }
  }

  &__sentence {
    margin-top: 24px;
    @include Body_1;
    color: $kelpsentinel-manatee;
  }

  &__profile-cards {
    margin-top: 56px;
    display: flex;
    gap: 20px;
    overflow-x: scroll;
    padding-bottom: 20px;
    @include tablet {
      padding-bottom: 40px;
    }
    @include desktop {
      overflow-x: hidden;
      display: grid;
      grid-template-columns: 300px 300px 300px 300px;
      row-gap: 30px;
      column-gap: 0px;
    }
  }

  &__profile-card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__profile-image {
    height: 226px;
    width: 214px;
    border: 0.79px solid $kelpsentinel-red;
    border-radius: 11.44px;
    object-fit: cover;

    @include tablet {
      width: 272px;
      height: 286.5px;
    }
    @include desktop {
      width: 285px;
      height: 300px;
    }
  }

  &__profile-name {
    @include H5;
    margin-top: 16px;
    @include desktop {
      @include H6;
    }
  }

  &__profile-title {
    @include ProfileJobTitle;
  }

  &__profile-subtitle {
    @include ProfileSubTitle;
  }
}
