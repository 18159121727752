@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/global" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables" as *;

.our-company {
  margin-top: 88px;

  &__container {
    position: relative;
    display: flex;
    background-color: $kelpsentinel-black;
    border: 1px solid $kelpsentinel-red;
    border-radius: 14.19px;
    padding: 20px;
    @include tablet {
      padding: 54.53px 76.16px 43.6px 76.16px;
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @include tablet {
      width: 400px;
    }
    @include desktop {
      width: 600px;
    }
  }
  &__title {
    @include H3;
    @include tablet {
      @include H2;
    }
  }
  &__paragraph {
    @include Body_1;
    color: $kelpsentinel-manatee;
  }
  &__button {
    cursor: pointer;
    @include Button_1;
    margin-top: 10px;
    align-self: flex-start;
    padding: 12px 20px;
    background-color: $kelpsentinel-red;
    border: 1px solid $kelpsentinel-red;
    border-radius: 6px;
    &:hover {
      animation: pulse;
      animation-duration: 1s;
      animation-iteration-count: 1;
    }
  }
  &__image {
    display: none;
    @include tablet {
      display: block;
    }
    position: absolute;
    rotate: -10deg;
    height: 350px;
    left: 470px;
    top: 100px;
    @include desktop {
      height: 400px;
      left: 700px;
      top: 80px;
    }
  }
}
