@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/global" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables" as *;

.image-and-list {
  margin-top: 88px;
  position: relative;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include tablet {
      width: 321px;
    }
    @include desktop {
      width: 488.14px;
    }
  }

  &__vertical-line {
    height: 160px;
    @include mobile {
      height: 130px;
    }
    @include tablet {
      height: 160px;
    }
    @include desktop {
      height: 90px;
    }
  }

  &__sub-heading {
    @include Title;
    color: $kelpsentinel-manatee;
  }

  &__heading {
    margin-top: 16px;
    @include H4;
    @include desktop {
      @include H3;
    }
  }

  &__quote-box {
    margin-top: 16px;
    display: flex;
    gap: 24px;
  }

  &__paragraph {
    @include Body_1;
    color: $kelpsentinel-manatee;
    @include desktop {
      font-size: 16px;
      line-height: 26px;
    }
  }

  &__explore-button {
    cursor: pointer;
    @include Button_1;
    align-self: flex-start;
    margin-top: 24px;
    padding: 12px 20px;
    background-color: $kelpsentinel-red;
    border: 1px solid $kelpsentinel-red;
    border-radius: 6px;
    &:hover {
      animation: pulse;
      animation-duration: 1s;
      animation-iteration-count: 1;
    }
  }

  &__image {
    margin-top: 56px;
    width: 270px;
    border: 1.49px solid $kelpsentinel-red;
    border-radius: 12px;
    object-fit: cover;
    @include mobile {
      height: 294px;
      width: 374px;
    }
    @include tablet {
      margin-top: 0;
      position: absolute;
      top: 0;
      left: 350px;
      height: 527px;
      width: 545px;
    }
    @include desktop {
      left: 511px;
      height: 540px;
      width: 689px;
    }
  }

  &__list-blocks {
    margin-top: 56px;
    display: flex;
    flex-direction: column;
    gap: 36px;
    @include tablet {
      display: grid;
      grid-template-columns: 288px 288px 288px;
      gap: 16px;
    }
    @include desktop {
      display: grid;
      grid-template-columns: 387px 387px 387px;
      column-gap: 20px;
      row-gap: 57px;
    }
  }

  &__list-block {
    display: flex;
    flex-direction: column;
    gap: 20.5px;
    width: 270px;
    padding: 28.5px;
    background-color: $kelpsentinel-black;
    border: 1px solid $kelpsentinel-red;
    border-radius: 12px;
    &:hover {
      background-color: $kelpsentinel-red;
    }
    @include mobile {
      height: 202px;
      width: 374px;
    }
    @include tablet {
      height: 221px;
      width: 288px;
      padding: 24px;

      &-1 {
        grid-column: 1;
        grid-row: 1;
      }
      &-2 {
        grid-column: 1;
        grid-row: 2;
      }
      &-3 {
        grid-column: 2;
        grid-row: 2;
      }
      &-4 {
        grid-column: 3;
        grid-row: 2;
      }
    }
    @include desktop {
      height: 213px;
      width: 387px;
    }
  }

  &__item-bullet {
    height: 27px;
    width: 27px;
    @include desktop {
      height: 33px;
      width: 33px;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }

  &__title {
    @include H4;
    @include tablet {
      font-size: 20px;
      line-height: 24px;
    }
    @include desktop {
      font-size: 24px;
      line-height: 36px;
    }
  }

  &__sentence {
    @include Body_2;
    @include mobile {
      line-height: 140%;
    }
    @include desktop {
      @include Body_3;
    }
  }
}
