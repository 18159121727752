@use "../src/styles/partials/colors" as *;
@use "../src/styles/partials/global" as *;
@use "../src/styles/partials/mixins" as *;
@use "../src/styles/partials/variables" as *;

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%; /* Ensures the body takes full viewport height */
  width: 100%; /* Ensures the body takes full viewport width */
}
