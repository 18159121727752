@use "../../../styles/partials/colors" as *;
@use "../../../styles/partials/global" as *;
@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables" as *;

.testimonials {
  margin-top: 88px;

  &__vertical-line {
    @include tablet {
      height: 80px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__sub-heading {
    @include Title;
    color: $kelpsentinel-manatee;
  }

  &__heading {
    margin-top: 16px;
    @include H2;
  }

  &__quote-box {
    margin-top: 16px;
    display: flex;
    gap: 24px;
  }

  &__paragraph {
    @include Body_1;
    color: $kelpsentinel-manatee;
  }

  &__explore-button {
    cursor: pointer;
    @include Button_1;
    align-self: flex-start;
    margin-top: 24px;
    padding: 12px 20px;
    background-color: $kelpsentinel-red;
    border: 1px solid $kelpsentinel-red;
    border-radius: 6px;
    &:hover {
      animation: pulse;
      animation-duration: 1s;
      animation-iteration-count: 1;
    }
  }

  &__cards {
    display: flex;
    margin-top: 56px;
    overflow-x: scroll;
    padding-bottom: 20px;
    gap: 20px;
  }

  &__card {
    display: flex;
    flex-direction: column;
    width: 322px;
    gap: 36.68px;
    flex-shrink: 0;
  }

  &__quote {
    @include Body_1;
    text-align: center;
  }

  &__profile {
    display: flex;
    gap: 16px;
    margin-left: 12px;
  }

  &__profile-text {
    display: flex;
    flex-direction: column;
    flex-direction: 6px;
  }

  &__profile-name {
    @include H4;
  }

  &__profile-title {
    @include Body_2;
    color: $kelpsentinel-manatee;
  }

  &__profile-image {
    height: 52.63px;
    width: 50px;
    border: 1px solid $kelpsentinel-red;
    border-radius: 6px;
    object-fit: cover;
  }
}
