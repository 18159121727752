// Main colors
$kelpsentinel-red: #ff2566;
$kelpsentinel-teal: #52a193;
$kelpsentinel-navy: #0d1824;
$kelpsentinel-red-transparent: rgb(255, 37, 102, 50);
$kelpsentinel-teal-transparent: rgb(82, 161, 147, 50);
$kelpsentinel-lightblack: #121212;
$kelpsentinel-lightblack-transparent: rbg(18, 18, 18, 50);
$kelpsentinel-black: #000000;
$kelpsentinel-white: #ffffff;
$kelpsentinel-manatee: #9ba1a8;
$kelpsentinel-antiflash: #eff2f5;
$kelpsentinel-gunmetal: #2c3235;
$kelpsentinel-error: #ff5c5c;
$kelpsentinel-warning: #fddd48;
$kelpsentinel-info: #5b8def;
$kelpsentinel-success: #2ee1c1;
